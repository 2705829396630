<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
          <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
          <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-col md="12" sm="6">
    <v-card height="300">
      <v-card-title>Add User</v-card-title>
    <v-container fluid>
      <v-row>
        <!-- //api-2 -->
        <v-col  cols="12" sm="4">
        </v-col>
        <v-col  cols="12" sm="4">
          <v-select
            :items="tclist"
            label="Tenant"
            v-model="selected_tenant"
            :loading="fetch_org_load"
            @change="fetchorganization()"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid style="display:none" id="orgdiv">
      <v-row>
        <v-col  cols="12" sm="4">
        </v-col>
        <v-col  cols="12" sm="4">
          <v-select
            :items="orglist"
            label="Organization"
            v-model="selected_org"
            :loading="check_admin_load"
            @change="addAdmin()"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    </v-card><br>
    </v-col>
    <v-card>
    <template>
      <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Username</th>
            <th class="text-left">Passward</th>
            <th class="text-left">Organization</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ username }}</td>
            <td>{{ passward }}</td>
            <td>{{ selected_org }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </template>
  </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    search: "",
    tclist: "",
    message: "",
    loading: true,
    selected_tenant: "",
    orglist: "",
    selected_org: "",
    status_reason:'',
    master_status: "",
    fetch_org_load: false,
    check_admin_load:false,
    username: '',
    passward: '',
    snackbar_msg: "",
    color: "",
    snackbar: false,
  }),

  mounted() {
    this.onload()
  },

  methods: {
    onload(){
      const data = {
      //uid:this.username
    };
    axios
      .post("/Admin/startCopy") //api-1
      .then(res => {
        // window.console.log(res);
        if (res.data.msg == "200") {
          this.tclist = res.data.tenants;
          //this.domainpath=res.data.domainpath
        } else {
          this.message = res.data.msg;
        }
      })
      .catch(error => {
        window.console.log("error:" + error);
      });
    },
    fetchorganization() {
      this.fetch_org_load = true;
      const data = {
        tenantid: this.selected_tenant
      };
      axios
        .post("/Admin/fetchOrganization", data)
        .then(res => {
          // window.console.log(res);
          if (res.data.msg == "200") {
            this.orglist = res.data.org;
            //this.orglist.name=res.data.org.name
            document.getElementById("orgdiv").style.display = "block";
            //this.domainpath=res.data.domainpath
          } else {
            this.message = res.data.msg;
          }
        })
        .catch(error => {
          window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
    },
    addAdmin() {
      this.check_admin_load = true;
     
      const data = {
        tenantid: this.selected_tenant,
        orgid: this.selected_org
      };
      
      axios
        .post("/Admin/addAdmin", data)
        .then(res => {
          // window.console.log(res);
          if (res.data.msg == "200") {
            this.onload()
            this.username= res.data.username
            this.passward = res.data.passward
            // adminid = res.data.adminid
            if (res.data.status == "NO") {
              this.onload()
              this.showSnackbar("#b71c1c", res.data.status_reason);
              //alert(res.data.status_reason);
              //alert(1)
            } else {
              this.showSnackbar("#4caf50", res.data.status_reason)
              this.onload()
            }
          }
        })
        .catch(error => {
          window.console.log("error:" + error);
        })
        .finally(() => {
          this.check_admin_load = false;
        });
    },
    showSnackbar(clr, msg) {
          this.snackbar = true;
          this.color = clr;
          this.snackbar_msg = msg;
      },
    
  }
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
</style>